<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
// 商品汇总查询
import TablePage from '@/components/tablePage'
import {
  singleListAPI,
  singleListSummaryAPI,
  billListAPI,
  billListSummaryAPI,
  cashierListAPI,
  cashierListSummaryAPI,
  categoryListAPI,
  categoryListSummaryAPI,
  timeListAPI,
  timeListSummaryAPI,
  listShopSummaryAPI,
  listShopSummarySummaryAPI,
  listMachineSummaryAPI,
  listMachineSummarySummaryAPI,
  listDateSummaryAPI,
  listDateSummarySummaryAPI
} from '@/api/O2OMall/orderManage/goodsQuery' // 门店销售列表
export default {
  name: 'SaleTotal',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '门店销售汇总',
        rowKey: 'shopId',
        search: [
          {
            label: '订单时间',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'categoryNos', label: '类别编号' },
              { filter: 'categoryNames', label: '类别名称' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'goods',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'category',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType != 'cashier',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCashier',
            option: {
              filter: 'cashierIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'cashier',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择收银员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'cashier' })
                    }
                  }
                ]
              }
            }
          }),
          {
            type: 'input',
            label: '门店销售查询',
            model: '',
            hide: () => this.options?.tabColumnType == 'note',
            filter: 'billNo',
            seniorSearch: true
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'shopGoods',
        tabsColumns: [
          {
            title: '门店商品汇总查询',
            type: 'shopGoods',
            getSummaryApi: singleListSummaryAPI,
            getListApi: singleListAPI,
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listSingleItemExport',
              exportName: '门店商品汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '商品汇总查询',
            type: 'goods',
            getSummaryApi: singleListSummaryAPI,
            getListApi: singleListAPI,
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listSingleItemExport',
              exportName: '商品汇总查询'
            },
            columns: [
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '门店分类汇总查询',
            type: 'shopCategory',
            getSummaryApi: categoryListSummaryAPI,
            getListApi: categoryListAPI,
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listCategorySummaryExport',
              exportName: '门店分类汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '分类汇总查询',
            type: 'category',
            getSummaryApi: categoryListSummaryAPI,
            getListApi: categoryListAPI,
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listCategorySummaryExport',
              exportName: '分类汇总查询'
            },
            columns: [
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '门店汇总查询',
            type: 'shop',
            getSummaryApi: listShopSummarySummaryAPI,
            getListApi: listShopSummaryAPI,
            // defaultBody: { searchType: 'shop' },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listShopSummaryExport',
              exportName: '门店汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '时间段汇总查询',
            type: 'time',
            getSummaryApi: timeListSummaryAPI,
            getListApi: timeListAPI,
            // defaultBody: { searchType: 'time' },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listTimeSlotSummaryExport',
              exportName: '时间段汇总查询'
            },
            columns: [
              {
                prop: 'timeSlot',
                label: '时间段',
                minWidth: 100
              },
              {
                prop: 'customerUnitPrice',
                label: '客单价',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'customerUnitQty',
                label: '客单数',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['customerUnitQty', 'unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '门店销售汇总查询',
            type: 'note',
            getSummaryApi: billListSummaryAPI,
            getListApi: billListAPI,
            // defaultBody: { searchType: 'note' },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listBillNoExport',
              exportName: '门店销售汇总查询'
            },
            columns: [
              {
                prop: 'billNo',
                label: '订单编号',
                type: 'link',
                click: 'update',
                minWidth: 180,
                align: 'center'
              },
              {
                prop: 'billDate',
                label: '订单时间',
                minWidth: 155
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'billSaleMoney',
                label: '销售金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'billFactMoney',
                label: '收款金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'payTypes',
                align: 'center',
                label: '收款方式',
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.(x => ({
                    ...x,
                    label: x.value,
                    prop: `payDetailList.${x.payMode}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find(y => y.payModeId === x.payMode)
                        ?.payMoney || 0
                  })) || []
              }
            ],
            summary: ['billSaleMoney', 'billFactMoney']
          },
          {
            title: '日期汇总查询',
            type: 'billDateStr',
            getSummaryApi: listDateSummarySummaryAPI,
            getListApi: listDateSummaryAPI,
            // defaultBody: { searchType: 'billDateStr' },
            exportOption: {
              fastExportUrl:
                '/api/system/mobile/report/sale/summary/listDateSummaryExport',
              exportName: '日期汇总查询'
            },
            columns: [
              {
                prop: 'billDateStr',
                label: '销售日期',
                minWidth: 155
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'update':
          if (this.options.tabColumnType === 'note') {
            this.$router.push({
              name: 'saleTotalDetail',
              query: {
                billNo: row.billNo,
                type: 'Update'
              }
            })
          }
          break
        default:
          break
      }
    }
  }
}
</script>
